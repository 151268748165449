<template>
  <div class="pandianList">
    <div class="main-container">
      <van-pull-refresh class="main-content" v-model="isRefresh" @refresh="onRefresh">

        <van-list
            class="data-list"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >

          <div class="data-item" v-for="(item, index) in taskList" :key="item.id">
            <pandian-item :data-info="item"></pandian-item>
          </div>

        </van-list>

      </van-pull-refresh>

    </div>

  </div>
</template>

<script>
import PandianItem from '@views/deviceManager/devicePandian/pandianItem'

export default {
  name: 'pandianList',
  components: { PandianItem },
  props:{
    status:{
      default:'',
    }
  },
  data() {
    return {
      loading: false,
      isRefresh: false,
      finished: false,
      page: 1,
      pageSize: 30,
      taskList: []
    }
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    onRefresh() {
      this.isRefresh = true
      this.page = 1
      this.getTaskList()
    },
    onLoad: function() {
      this.page = this.page + 1
      this.isRefresh = true
      this.getTaskList()
    },
    getTaskList: function() {
      let getList = () => {
        return new Promise(resolve => {
          let userInfo = this.$store.getters.userInfo
          this.$api.pandianModule.taskList({
            inventoryUserId:userInfo.id,
            page: this.page,
            pageSize: this.pageSize,
            status: this.status,
            query: '',
            startTime: 1685721600000,
            endTime: new Date().getTime()
          }).then(res => {
            if (typeof res.data == 'undefined' || res.data == null) {
              resolve({ list: [], reslut: false, totalCount: 0 })
              return
            }
            resolve({ list: res.data, reslut: true, totalCount: res.totalCount })
          }).catch(e => {
            resolve({ list: [], reslut: false, totalCount: 0 })
          })
        })
      }

      this.loading = true
      getList().then(res => {
        this.isRefresh = false
        this.loading = false
        this.taskList = this.page == 1 ? res.list : this.taskList.concat(res.list);
        this.finished = this.taskList.length >= res.totalCount
      })

    }
  }
}
</script>

<style lang="scss" scoped>

.pandianList {

}

</style>