<template>
  <div class="pandianItem">
    <div class="header">
      {{ $getDataValue(dataInfo, 'taskName', '') }}
    </div>
    <div class="line"></div>
    <div class="contentView">
      <div class="content" v-for="(item) in keyList">
        <div class="title">{{ item.name }}</div>
        <div class="detail">
          {{ showMsg(item) }}
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
    <div class="processContentView">
      <div class="title">当前进度</div>
      <div class="process">
        <van-progress :percentage="processNumber"/>
      </div>
    </div>
    <div class="line"></div>

    <div class="actionContentView">
      <div class="actionContent">
        <div class="title">全部</div>
        <div class="detail">{{ $getDataValue(dataInfo, 'alreadyCount', 0) }}</div>
      </div>
      <div class="actionContent">
        <div class="title">待盘点</div>
        <div class="detail">{{ shouldFirmVolumeCount }}</div>
      </div>
      <div class="actionContent">
        <div class="title">已盘点</div>
        <div class="detail">{{ $getDataValue(dataInfo, 'firmVolumeCount', 0) }}</div>
      </div>
    </div>

    <div class="line"></div>

    <div class="start">
      <van-button type="info" size="normal" block @click="pushList">开始盘点</van-button>

    </div>

  </div>
</template>

<script>

import { formatDate, formatDayDate } from '@utils/utils'

export default {
  name: 'pandianItem',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    processNumber() {
      let alreadyCount = this.$getDataValue(this.dataInfo, 'alreadyCount', 0)
      let firmVolumeCount = this.$getDataValue(this.dataInfo, 'firmVolumeCount', 0)
      if (alreadyCount == 0) {
        return 0
      }
      return  parseFloat((firmVolumeCount / alreadyCount * 100.0).toFixed(1));
    },
    shouldFirmVolumeCount() {
      return this.$getDataValue(this.dataInfo, 'alreadyCount', 0) - this.$getDataValue(this.dataInfo, 'firmVolumeCount', 0)
    }
  },
  data() {
    return {
      keyList: [
        { key: 'createdByUserName', name: '创始人' },
        { key: 'alreadyCount', name: '应盘总数' },
        { key: 'inventoryCycleStartTime', name: '计划开始' },
        { key: 'inventoryCycleEndTime', name: '计划结束' },
        { key: 'status', name: '状态' }
      ]
    }
  },
  methods: {
    pushList:function() {
      this.$push('devicePandianList', {taskId:this.$getDataValue(this.dataInfo, 'id', ''), serialNumber:this.$getDataValue(this.dataInfo, 'serialNumber', '')})
    },
    showMsg: function(item) {
      if (item.key == 'inventoryCycleStartTime' || item.key == 'inventoryCycleEndTime') {
        let time = this.$getDataValue(this.dataInfo, item.key, 0)
        if (time == 0) {
          return ''
        }
        return this.formatDayDate(time)
      } else if (item.key == 'status') {
        let status = this.$getDataValue(this.dataInfo, item.key, 0)
        /*
        * 0未开始 1进行中 2未按计划未完成 3已完成
        */
        if (status == 1) {
          return '进行中'
        } else if (status == 2) {
          return '未按计划未完成'
        } else if (status == 3) {
          return '已完成'
        } else if (status == 0) {
          return '未开始'
        }
        return ''
      } else {
        return this.$getDataValue(this.dataInfo, item.key, '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.pandianItem {
  background-color: white;

  .header {
    color: $color2;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    padding: 10px 0px 10px 10px;
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: $border_color1;
  }

  .processContentView {
    display: flex;
    align-items: center;
    padding-bottom: 5px;

    .title {
      width: 100px;
      text-align: right;
      line-height: 15px;
      font-size: 13px;
      padding-right: 5px;
      color: $color4;
    }

    .process {
      width: calc(100% - 100px);
      padding: 0px 10px 0px 10px;
    }

  }

  .contentView {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0px 5px 0px;

    .content {
      width: 50%;
      padding: 5px 0px 0px 0px;
      flex-shrink: 0;

      .title {
        width: 100px;
        text-align: right;
        line-height: 15px;
        color: $color4;
        float: left;
        padding-right: 5px;
        font-size: 13px;
      }

      .detail {
        width: calc(100% - 100px);
        line-height: 15px;
        font-size: 13px;
        float: right;
        white-space: pre-wrap;
        padding-right: 5px;
      }
    }
  }

  .actionContentView {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px 10px 0px;

    .actionContent {
      width: 33%;

      .title {
        width: 100%;
        line-height: 20px;
        height: 20px;
        text-align: center;
        padding-bottom: 30px;
        color: $color4;
      }

      .detail {
        width: 100%;
        line-height: 25px;
        height: 25px;
        font-size: 20px;
        text-align: center;
      }

    }


  }


  .start {
    padding: 10px;
  }

}

</style>