<template>
  <div class="devicePandian">
    <tabs1 style="background-color: white" :color="'#0279E1'" v-model="activeTab" :options="tabList"
           @change="tabChange"
    ></tabs1>
    <div class="page-container">
      <pandian-list ref="pandianList" :status="status"></pandian-list>
    </div>
  </div>
</template>

<script>
import PandianList from '@views/deviceManager/devicePandian/pandianList'

export default {
  name: 'devicePandian',
  components: { PandianList },
  data() {
    return {
      status: '',
      activeTab: '',
      tabList: [
        {
          title: '全部',
          path: '',
          status: ''
        },
        {
          title: '已完成',
          path: '3',
          status: '3'
        },
        {
          title: '进行中',
          path: '1',
          status: '1'
        },
        {
          title: '未完成',
          path: '2',
          status: '2'
        }
      ]
    }
  },
  methods: {
    tabChange(e) {
      if (e == this.activeTab) {
        return
      }
      this.activeTab = e
      let item = null;
      for (let i = 0; i < this.tabList.length; i++) {
        let data = this.tabList[i];
        if (data.path == e) {
          item = data;
          break;
        }
      }
      if (item == null) {
        return;
      }
      this.status = item['status'];
      this.$nextTick(() => {
        this.$refs['pandianList'].onRefresh();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.devicePandian {
  height: 100%;

  .page-container {
    height: calc(100% - 32px - 20px);
    margin-top: 10px;
    overflow-y: auto;
  }
}

</style>